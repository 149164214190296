import React, { FC, useState, useEffect, CSSProperties } from 'react'
import Styles from "../styles/CheckBox.module.scss"


type Props = {
    isChecked: boolean
    label: string
    labelStyle?: CSSProperties
    onChange : ( isChecked: boolean ) => void
}

export const Checkbox: FC<Props> = ( { isChecked, label, labelStyle, onChange} ) => {

    return (
        <div className={Styles.container}>
            <input 
                checked={ isChecked }
                className={Styles.checkbox}
                id={label}
                type="checkbox" 
                onChange={ ( e ) => onChange( e.target.checked ) } />
            <label className={Styles.label} style={ labelStyle } htmlFor={label}>{ label }</label>
        </div>
    )
}
