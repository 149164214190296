import React, { useContext, useEffect, useState } from "react"
import Styles from "../../styles/welcome.module.scss";
import { navigate } from "gatsby";
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import GeneralButton from "../../components/atoms/GeneralButton";
import { ToastContext } from "../../context/ToastContext";
import { WelcomeContext } from "../../context/WelcomeContext";
import { BACK_BUTTON_STR } from "../../constant/const";
import InputForm from "../../components/atoms/InputForm";
import { Checkbox } from "../../components/Checkbox";
import { useRedirectFromWelcome } from "../../hooks/useRedirectFromWelcome";

const headerOption = {
    headerTitle: "ようこそ",
    leftIcon: BACK_BUTTON_STR,
}

const WelcomePage = () => {

    useRedirectFromWelcome()
    const { addToast } = useContext( ToastContext )
    const { answers, setAnswers, anotherAnswer, setAnotherAnswer } = useContext( WelcomeContext )

    const onSelectChange = ( value: string ) => {
        const newAnswers = answers.map( answer => (
            answer.label === value ? {
                ...answer,
                isSelected: !answer.isSelected
            } :
                answer ) )
        setAnswers( [ ...newAnswers ] )
    }

    useEffect( () => {
        document.documentElement.scrollTop = 0
    }, [] )

    const answerExists = ( 0 < answers?.filter( ans => ans.isSelected ).length ) || !!anotherAnswer

    return (
        <Layout headerOption={ headerOption } footerHide={ true }>
            <>
                <SEO title="Home" />
                <div className={ Styles.container }>
                    <h1 className={ Styles.title }>{ "2. アンケート" }</h1>
                    <p className={ Styles.description }>ClubCloudに求めるものをお答えください</p>
                    <div className={ Styles.content_wrapper }>
                        <div className={ Styles.content }>
                            <div style={ { marginBottom: 32 } }>
                                { answers?.map( ans =>
                                    <Checkbox
                                        isChecked={ ans.isSelected }
                                        label={ ans.label }
                                        onChange={ () => onSelectChange( ans.label ) } /> ) }
                            </div>
                            <InputForm
                                name="name"
                                label="その他"
                                value={ anotherAnswer }
                                type="text"
                                labelStyle={ { fontWeight: "bold" } }
                                placeholder="例: ストレージの代わりに使いたい"
                                onChange={ ( name, value ) => setAnotherAnswer( value ) }
                            />
                        </div>
                    </div>
                    <div className={ Styles.button_container }>
                        <GeneralButton
                            outline
                            title="戻る"
                            style={ { minWidth: 200, } }
                            className={ Styles.back_button }
                            onClick={ () => navigate( "/welcome" ) } />
                        <GeneralButton
                            title="次へ"
                            style={ { minWidth: 200, } }
                            isDisabled={ !answerExists }
                            className={ Styles.next_button }
                            disabledOnClick={ () => addToast( {
                                position: "center",
                                type: "warn",
                                text: "選択、もしくは入力してください"
                            } ) }
                            onClick={ () => navigate( "/welcome/user_team_join" ) } />
                    </div>
                </div>
            </>
        </Layout>
    )
}

export default WelcomePage
